import { useState, useEffect } from 'react';
import { getProgressionsOfEntitiesLast30Days } from '@shared/api/all/stats';

import { Line } from '@ant-design/plots';

const ServicesGraphs = () => {
   const [data, setData] = useState([]);

   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = () => {
      getProgressionsOfEntitiesLast30Days().then((res) => {
         let apiArr = res?.json?.data || [];
         const arr = [];
         apiArr.map((e) => {
            const date = e?.date
               ?.replace(`2023-`, ``)
               .replace(`2024-`, ``)
               .replace(`2025-`, ``);

            if (e?.driver) {
               arr.push({
                  date,
                  value: e.driver,
                  category: 'Водителей'
               });
            }

            if (e?.seller) {
               arr.push({
                  date,
                  value: e.seller,
                  category: 'Продавцов'
               });
            }

            if (e?.lead) {
               arr.push({
                  date,
                  value: e.lead,
                  category: 'Заявок'
               });
            }
         });

         setData(arr.reverse());
      });
   };

   const config = {
      data,
      xField: 'date',
      yField: 'value',
      seriesField: 'category',
      color: ['#1979C9', '#D62A0D', '#FAA219', 'green']
   };

   return <Line {...config} />;
};

export default ServicesGraphs;
