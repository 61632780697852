import React, { useState, useEffect } from 'react';
import { StatusTag } from '@shared/ui';
import {
   Space,
   Spin,
   Button,
   Typography,
   Tag,
   Collapse,
   Divider,
   List,
   Tooltip,
   Steps
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { DateWithAgo } from '@shared/ui';
import { GetLeadList } from '@features/list-lead/model/GetLeadList';
import { getTimeAgo } from '@shared/utils/timeAgo';
import dayjs from 'dayjs';
import ListLeadsOfRoute from '@features/list-route/ui/ListLeadsOfRoute';
import { BalanceCount } from '@features/list-driver/ui/DriverComponents';
import { GetDriversBalances } from '@features/list-balance-history/model/GetDriversBalances';
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';
import ModalButtonBalanceCreate from '@features/list-balance-history/ui/ModalButtonBalanceCreate';
import ModalBalanceForm from './ModalBalanceForm';

const LIMIT = 3;
const ListOfBalance = ({ driverId, mobileNumber }) => {
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [count, setCount] = useState(1);
   const [selectedData, setSelectedData] = useState(null);
   const [modalShow, setModalShow] = useState(null);

   const navigate = useNavigate();

   const fetchData = (d, m, currentPage) => {
      setLoading(true);

      GetDriversBalances({
         page: currentPage,
         limit: LIMIT,
         sort: 'id',
         order: 'desc',
         driverId: d,
         mobileNumber: m
      })
         .then((res) => {
            if (currentPage === 1) {
               setData((e) => [...res.data]);
            } else {
               setData((e) => [...e, ...res.data]);
            }

            setLoading(false);
            setCurrentPage(currentPage);
            setCount(res.count);
         })
         .catch((err) => {
            console.error(err);
            setLoading(false);
         });
   };

   useEffect(() => {
      fetchData(driverId, mobileNumber, currentPage);
   }, [driverId, mobileNumber, currentPage]);

   if (loading) {
      return (
         <Space
            style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: '300px',
               width: '100%'
            }}>
            <Spin />
         </Space>
      );
   }

   const isDisabled = count - currentPage * LIMIT <= 0;

   return (
      <Space direction="vertical" style={{ width: '100%' }}>
         <ModalBalanceForm
            selectedData={selectedData}
            modalShow={modalShow}
            closeModal={() => {
               setSelectedData(null);
               setModalShow(null);
               fetchData(driverId, mobileNumber, 1);
            }}
         />
         <Divider orientation="left">
            Баланс заявок{' '}
            <Tag bordered={false}>
               {' '}
               <span
                  className="green-span-url"
                  style={{ padding: '5px 0', whiteSpace: 'nowrap' }}
                  onClick={() =>
                     navigate(
                        `/drivers-balance-history?current=1&pageSize=10&driverId=${driverId}&mobileNumber=${mobileNumber}`
                     )
                  }>
                  История
               </span>
            </Tag>
         </Divider>

         <div
            style={{
               fontSize: '60px',
               fontWeight: '500',
               color: 'green',
               marginBottom: '10px',
               textAlign: 'center'
            }}>
            <BalanceCount driverId={driverId} mobileNumber={mobileNumber} />
         </div>
         <ModalButtonBalanceCreate
            driverId={driverId}
            mobileNumber={mobileNumber}
            closeModal={() => fetchData(driverId, mobileNumber, 1)}
         />

         {data.map((e) => (
            <div
               className="note-message"
               style={{
                  margin: '2px 0',
                  padding: '8px 8px',
                  border: 0,
                  background: '#ffffff'
               }}>
               <div
                  style={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center'
                  }}>
                  <span>
                     {' '}
                     <span
                        style={{
                           display: 'inline-block',
                           minWidth: '48px',
                           textAlign: 'center',
                           fontSize: '12px',
                           fontWeight: '500',
                           lineHeight: '20px',
                           padding: '0 5px',
                           marginRight: '5px',
                           borderRadius: '4px',
                           background: 'rgba(0, 0, 0, 0.04)',
                           color:
                              e.change === 0
                                 ? 'black'
                                 : e.change > 0
                                 ? 'green'
                                 : 'red'
                        }}>
                        {e.change === 0
                           ? 0
                           : e.change > 0
                           ? '+ ' + e.change
                           : '- ' + Math.abs(e.change)}
                     </span>{' '}
                     {e.note}
                  </span>

                  <span
                     style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'center'
                     }}>
                     <Tooltip
                        placement="top"
                        title={
                           <div className="note-date-tooltip">
                              <DateWithAgo
                                 date={e.createdAt}
                                 showAgo={true}
                                 showDate={false}
                              />
                           </div>
                        }>
                        {`${timestampToNormalDDMMYY(
                           e.createdAt
                        )} в ${timestampToNormalTime(e.createdAt)}`}
                     </Tooltip>

                     <Tooltip placement="top" title="Редактировать">
                        <div
                           onClick={() => {
                              setModalShow(e);
                              setSelectedData(e);
                           }}>
                           <EditOutlined style={{ color: 'green' }} />
                        </div>
                     </Tooltip>
                  </span>
               </div>
            </div>
         ))}

         <Space
            direction="horizontal"
            style={{ width: '100%', justifyContent: 'center', marginTop: '15px' }}>
            <Button
               type="primary"
               loading={loading}
               icon={<DownloadOutlined />}
               onClick={() => setCurrentPage((e) => e + 1)}
               disabled={isDisabled}>
               Загрузить больше
            </Button>
         </Space>
      </Space>
   );
};

export default ListOfBalance;
