import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { getRoutes } from '@shared/api/all/route';
import { getLeads } from '@shared/api/all/lead';
import { getLeadHistoryStatuses } from '@shared/api/all/statuses';
import { getDriverBalance } from '@shared/api/all/balanceDriver';
import { useNavigate } from 'react-router-dom';
import ModalButtonBalanceCreate from '@features/list-balance-history/ui/ModalButtonBalanceCreate';
import ModalListOfLeads from '@features/list-driver/ui/ModalListOfLeads';

const BalanceCount = ({ driverId, mobileNumber }) => {
   const [count, setCount] = useState(0);
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

   useEffect(() => {
      fetchData(driverId, mobileNumber);
   }, [driverId, mobileNumber]);

   const fetchData = async (driverId, mobileNumber) => {
      let rr = 0;
      setLoading(true);

      rr = await getDriverBalance(driverId, mobileNumber)
         .then((res) => res?.json?.data || 0)
         .catch((e) => console.error(e));

      setLoading(false);
      setCount(rr);
   };

   if (loading) {
      return <Spin size="small" />;
   }

   return <>{count || 0}</>;
};

const LeadCount = ({ driverId }) => {
   const [count, setCount] = useState(0);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      fetchDriversCount(driverId);
   }, [driverId]);

   const fetchDriversCount = async (driverId) => {
      let rr = 0;
      setLoading(true);

      rr = await getLeads({
         pageSize: 1,
         page: 1,
         driverId: driverId,
         status: 'finished'
      })
         .then((res) => res?.json?.count || 0)
         .catch((e) => console.error(e));

      setLoading(false);
      setCount(rr);
   };

   if (loading) {
      return <Spin size="small" />;
   }

   return count;
};

const LeadHistoryCount = ({ driverId, status }) => {
   const [count, setCount] = useState(0);
   const [loading, setLoading] = useState(false);
   const [list, setList] = useState([]);

   useEffect(() => {
      fetchDriversCount(driverId);
   }, [driverId]);

   const fetchDriversCount = async (driverId) => {
      let rr = 0;
      setLoading(true);

      rr = await getLeadHistoryStatuses({
         pageSize: 1,
         page: 1,
         driverId: driverId,
         status: status
      })
         .then((res) => res?.json)
         .catch((e) => console.error(e));

      const count = rr?.count || 0;
      const data = rr?.data || [];

      setLoading(false);
      setCount(count);
      setList(data);
   };

   if (loading) {
      return <Spin size="small" />;
   }

   return (
      <>
         {count} {count ? <ModalListOfLeads data={list} /> : ''}
      </>
   );
};

const FinishedRouteCount = ({ driverId }) => {
   const [count, setCount] = useState(0);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      fetchRoutesCount(driverId);
   }, [driverId]);

   const fetchRoutesCount = async (driverId) => {
      let rr = 0;
      setLoading(true);

      rr = await getRoutes({
         pageSize: 1,
         page: 1,
         driverId: driverId,
         status: 'finished'
      })
         .then((res) => res?.json?.count || 0)
         .catch((e) => console.error(e));

      setLoading(false);
      setCount(rr);
   };

   if (loading) {
      return <Spin size="small" />;
   }

   return count;
};

export { FinishedRouteCount, LeadCount, LeadHistoryCount, BalanceCount };
