import { Button, Modal } from 'antd';
import { useState } from 'react';
import { DollarOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { StatusTag } from '@shared/ui';

const ModalListOfLeads = ({ data }) => {
   const navigate = useNavigate();
   const [isModalOpen, setIsModalOpen] = useState(false);

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
   };

   const handleCancel = () => {
      setIsModalOpen(false);
   };

   return (
      <>
         <span
            className="green-span-url"
            style={{ padding: '5px 0', whiteSpace: 'nowrap' }}
            onClick={showModal}>
            Посмотреть список
         </span>

         <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            onClose={handleCancel}
            title={`Список заявок`}
            footer={null}
            width={600}
            destroyOnClose={true}>
            {data.map((element) => (
               <div key={`list-leads-${element.entityId}`}>
                  <span
                     className="green-span-url"
                     style={{ padding: '5px 0' }}
                     onClick={() => navigate(`/leads/${element.entityId}`)}>
                     {`Заявка №${element.entityId}`}
                  </span>
                  {' • '}
                  <StatusTag status={element.status} type="lead" />

                  {element.comment && ` • ${element.comment}`}
               </div>
            ))}
         </Modal>
      </>
   );
};

export default ModalListOfLeads;
