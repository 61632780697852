import React, { useState, useRef, useEffect } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { Button, message } from 'antd';

const ExportToCSVModalButton = ({
   fetchData,
   dataParams,
   buttonText = 'Экспортировать в CSV'
}) => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState({
      data: [],
      metaData: []
   });
   const csvInstance = useRef();

   const getData = () => {
      setIsLoading(true);

      fetchData({
         page: 1,
         limit: 100000,
         ...dataParams.sorter,
         ...dataParams.filters
      })
         .then(async (res) => {
            setData(res);

            setTimeout(() => {
               csvInstance.current.link.click();
               message.success('Данные успешно экспортированы');
               setIsLoading(false);
            }, 500);
         })
         .catch(() => {
            setIsLoading(false);
         });
   };

   return (
      <>
         <Button
            icon={<DownloadOutlined />}
            onClick={getData}
            loading={isLoading}
            disabled={isLoading}>
            {buttonText}
         </Button>

         {data?.data.length > 0 ? (
            <CSVLink
               filename={'file.csv'}
               data={data?.data}
               ref={csvInstance}
               headers={data.metaData}
               separator={';'}
            />
         ) : null}
      </>
   );
};

export default ExportToCSVModalButton;
