import { get, post, put, generateQueryParams, _delete } from '../fetch.js';

export const getLeadStatusesById = (id) => get(`/statuses/lead/${id}`);

export const getLeadHistoryStatuses = (params) =>
   get(generateQueryParams(`/statuses/leads`, params));
export const getRouteStatusesById = (id) => get(`/statuses/route/${id}`);

export const getLeadStatusesExcel = (params) =>
   get(generateQueryParams(`/statusesexcel/leads`, params));
